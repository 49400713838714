<template>
  <b-overlay
    variant="light"
    :show="showLoading"
    spinner-variant="primary"
    opacity=".75"
    rounded="sm"
    class="location-container d-flex align-items-center"
  >
    <baidu-map
      class="bm-view "
      :center="center"
      :zoom="12"
      :scroll-wheel-zoom="true"
      :mapStyle="mapStyle"
      @ready="mapReady"
    >
      <bm-map-type
        :map-types="['BMAP_NORMAL_MAP', 'BMAP_HYBRID_MAP']"
        anchor="BMAP_ANCHOR_TOP_LEFT"
      />
      <!--      <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-navigation>-->
      <bm-geolocation
        anchor="BMAP_ANCHOR_TOP_LEFT"
        :show-address-bar="true"
        :auto-location="true"
      />
      <!--      全景-->
      <!--            <bm-panorama></bm-panorama>-->
      <bml-marker-clusterer
        :average-center="true"
        animation="BMAP_ANIMATION_BOUNCE"
      >
        <bm-marker
          v-for="(device,index) of geoDevices"
          :key="index"
          :icon="{url: 'http://file.cloudxin.ltd/aiot/icon/location_red.svg',opts: { imageSize: {width: 35, height: 35}}, size: {width: 35, height: 35}}"
          :position="{lng: device.geometry.coordinates[0], lat: device.geometry.coordinates[1]}"
          @click="geoDeviceClickHandle(device)"
        />
      </bml-marker-clusterer>
      <location-overlay
        v-if="showGeoDeviceInfo"
        :position="geoDevicePosition"
        :active="showGeoDeviceInfo"
        :device-id="geoDevice.properties.objectId"
      >
        <!--        @mouseover.native="showGeoDeviceInfo = true"-->
        <!--        @mouseleave.native="showGeoDeviceInfo = false">-->
      </location-overlay>
    </baidu-map>
    <div
      v-if="showLocationLoading"
      class="bm-view suspension top d-flex justify-content-center"
    >
      <div class="suspension-location p-1 mt-1 d-flex align-items-center justify-content-center">
        <b-spinner
          class="mr-1"
          variant="primary"
        />
        <span class="text-body x-text-bold">定位中...</span>
      </div>
    </div>
    <div
      v-if="!showLocationLoading && isCtrlPanelOpen && !isProduct"
      class="suspension center top h-25 d-flex justify-content-center mt-1 x-anim-fade-in"
      style="width: 40%"
    >
      <div class="suspension-search mr-1 p-1 d-flex align-items-center justify-content-center">
        <v-select
          v-model="productId"
          :options="products"
          class="w-100"
          style="line-height: 2.6rem"
          label="label"
          :disabled="isProduct"
          :reduce="option => option.id"
          :get-option-label="option => `${option.name} ( ${option.id} )`"
          placeholder="请选择产品"
        >
          <template slot="no-options">
            {{ $t('noData') }}
          </template>
        </v-select>
      </div>
      <div class="suspension-search p-1 d-flex align-items-center justify-content-center">
        <b-form-input
          v-model="deviceName"
          class="w-100"
          placeholder="请输入设备编码"
        />
      </div>
    </div>
    <div class="suspension right x-w-20 mt-1 x-anim-fade-in">
      <div
        v-if="false && !showLoading && isProduct"
        class="suspension-search d-flex align-items-center p-1">
        <v-select
          v-model="deviceId"
          :options="devices"
          class="x-w-100 "
          style="line-height: 2.6rem"
          label="label"
          :reduce="option => option.id"
          :get-option-label="option => `${option.name} ( ${option.id} )`"
          placeholder="请选择设备"
        >
          <template slot="no-options">
            {{ $t('noData') }}
          </template>
        </v-select>
      </div>
    </div>
    <div
      v-if="!showLoading && isCtrlPanelOpen"
      class="suspension d-flex center mb-1  x-anim-fade-in x-w-60 justify-content-center"
      :class="isProduct ? 'top align-items-start mt-1 ' : 'center bottom align-items-end'"
    >
      <div class="suspension-statistics x-w-100 p-1 d-flex align-items-center justify-content-center">
        <div class="w-25 d-flex justify-content-center align-items-center">
          <span class="text-secondary">设备总数：</span>
          <span class="text-primary x-link" @click="queryDeviceState = undefined">{{ totalCount }}</span>
        </div>
        <div class="w-25 d-flex justify-content-center align-items-center">
          <div class="font-medium-3 mr-50 text-success">●</div>
          <span class="text-secondary">在线设备：</span>
          <span class="text-success x-link" @click="queryDeviceState = $x.biz.DevState.ONLINE">{{ onlineCount }}</span>
        </div>
        <div class="w-25 d-flex justify-content-center align-items-center">
          <div class="font-medium-3 mr-50 text-danger">●</div>
          <span class="text-secondary">离线设备：</span>
          <span class="text-danger x-link" @click="queryDeviceState = $x.biz.DevState.OFFLINE">{{ offlineCount }}</span>
        </div>
        <div class="w-25 d-flex justify-content-center align-items-center">
          <div class="font-medium-3 mr-50 text-warning">●</div>
          <span class="text-secondary">未激活设备：</span>
          <span class="text-warning x-link" @click="queryDeviceState = $x.biz.DevState.NOT_ACTIVE">{{ notActiveCount }}</span>
        </div>
      </div>
    </div>
    <div
      v-if="!showLoading && !isProduct"
      class="ctrl-panel d-none d-md-block x-anim-fade-in"
      :class="{'open': isCtrlPanelOpen}"
    >
      <b-link
        v-tooltip.hover.bottom="`${isCtrlPanelOpen ? '关闭' : '打开'}控制面板`"
        class="ctrl-panel-toggle d-flex align-items-center justify-content-center"
        @click="isCtrlPanelOpen = !isCtrlPanelOpen"
      >
        <feather-icon
          icon="ApertureIcon"
          size="15"
          class="spinner"
        />
      </b-link>
      <x-table
        ref="table"
        table-type="list"
        class="m-1"
        :options="options"
        sticky-header="40vh"
        small
        :fixed="false"
        :card="false"
        simple-pagination
        :show-actions="false"
        @search="searchHandle"
        @rowLocation="rowLocation"
      />
    </div>
  </b-overlay>
</template>

<script>
import {
  BOverlay, BSpinner, BFormInput, BLink,
} from 'bootstrap-vue'
import XTable from '@core/components/cx/table/XTable.vue'
import userAppConfig from '@core/app-config/useAppConfig'
import vSelect from 'vue-select'
import { BmlMarkerClusterer } from 'vue-baidu-map'
import { queyrGeoDevices } from '@/api/ops/geo'
import {
  queryAllProducts,
} from '@/api/device-manage/product-list'

import {
  query as queryDevices, queryStateCount, queryNoPage,
} from '@/api/device-manage/device-list'
import LocationOverlay from './LocationOverlay.vue'

export default {
  components: {
    BOverlay,
    BSpinner,
    XTable,
    LocationOverlay,
    BmlMarkerClusterer,
    BFormInput,
    vSelect,
    BLink,
  },
  props: {
    pid: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      isProduct: !!this.pid,
      map: undefined,
      isCtrlPanelOpen: true,
      showLoading: false,
      showLocationLoading: false,
      showGeoDeviceInfo: false,
      geoDevicePosition: {
        lng: undefined,
        lat: undefined,
      },
      geoDevices: [],
      // 点击设备
      geoDevice: undefined,
      products: [],
      // 选择的产品
      product: {},
      // 选择的产品ID
      productId: this.pid,
      // 设备列表
      devices: [],
      // 选择的设备
      deviceName: undefined,
      // 选择的设备
      deviceId: undefined,
      // 查询指定状态设备条件
      queryDeviceState: undefined,
      // 所有设备
      totalCount: 0,
      // 在线设备数量
      onlineCount: 0,
      // 离线设备数量
      offlineCount: 0,
      // 未激活设备数量
      notActiveCount: 0,
      // 主题
      skin: userAppConfig().skin,
      center: {
        lng: '116.397128',
        lat: '39.916527',
      },
      options: {
        exportBtn: false,
        printBtn: false,
        actions: [{ action: 'view', name: '查看', icon: 'EyeIcon', url: '/device-center/device/view/{id}', tip: '查看设备详情' },
          { action: 'location', name: '定位', icon: 'NavigationIcon' }],
        actionFilter: () => true,
        collapseExpandAll: true,
        columns: [{
          label: '设备ID',
          prop: 'id',
          sortable: true,
          editDisable: true,
          rules: {
            rule: 'required',
          },
        }, {
          label: '设备名称',
          prop: 'name',
          sortable: false,
          rules: {
            rule: 'required',
          },
        }, {
          label: '产品',
          prop: 'productName',
          sortable: false,
          addDisable: true,
          editDisable: true,
          rowShow: false,
          rules: {
            rule: 'required',
          },
        }, {
          label: '状态',
          prop: 'state',
          type: 'select',
          dictData: this.$x.biz.DevState.DictData,
          props: {
            label: 'text',
            value: 'value',
          },
          selectVariant: prop => {
            if (prop.value === this.$x.biz.DevState.ONLINE) {
              return 'text-success'
            }
            if (prop.value === this.$x.biz.DevState.OFFLINE) {
              return 'text-danger'
            }
            return 'warning'
          },
          rowSelectDot: true,
          sortable: false,
          addShow: false,
          editShow: false,
        }],
      },
    }
  },
  computed: {
    mapStyle() {
      return this.skin.value === 'dark' ? {
        style: 'midnight',
      } : {
        styleJson: [
          {
            featureType: 'all',
            elementType: 'geometry',
            stylers: {
              hue: '#007fff',
              saturation: 89,
            },
          },
          {
            featureType: 'water',
            elementType: 'all',
            stylers: {
              color: '#ffffff',
            },
          },
        ],
      }
    },
  },
  watch: {
    productId(val) {
      queyrGeoDevices({
        productId: val,
        deviceName: this.deviceName,
      }).then(resp => {
        this.geoDevices = resp.data.result.features
      })
      this.$refs.table.__searchHandle()
      this.getCountStatistics()
    },
    deviceId(val) {
      this.rowLocation({
        id: val,
      })
    },
    deviceName(val) {
      queyrGeoDevices({
        productId: this.productId,
        deviceName: val,
      }).then(resp => {
        this.geoDevices = resp.data.result.features
      })
      this.$refs.table.__searchHandle()
    },
    queryDeviceState() {
      if (this.isProduct) {
        return
      }
      this.$refs.table.__searchHandle()
    },
  },
  created() {
    queyrGeoDevices().then(resp => {
      this.geoDevices = resp.data.result.features
    })
    queryAllProducts().then(resp => {
      this.products = resp.data.result
    })
    if (this.isProduct) {
      queryNoPage({ productId: this.productId, name: this.deviceName, state: this.queryDeviceState }, this.productId).then(resp => {
        this.devices = resp.data.result
      })
    }
    this.getCountStatistics()
  },
  destroyed() {
    // this.$x.ws.unsubscribe('location', this.$x.ws.TYPE.REGISTER)
    // this.$x.ws.unsubscribe('location', this.$x.ws.TYPE.UNREGISTER)
    // this.$x.ws.unsubscribe('location', this.$x.ws.TYPE.ONLINE)
    // this.$x.ws.unsubscribe('location', this.$x.ws.TYPE.OFFLINE)
    // this.$x.ws.unsubscribe('location', this.$x.ws.TYPE.PROPERTY_REPORT)
  },
  methods: {
    mapReady({ BMap, map }) {
      this.map = map
      this.showLocationLoading = true
      const location = new BMap.Geolocation()
      const _this = this
      // 获取自动定位获取的坐标信息
      location.getCurrentPosition(
        r => {
          _this.center = {
            lng: r.point.lng,
            lat: r.point.lat,
          }
          this.showLocationLoading = false
          this.showLoading = false
        },
        () => {
          this.showLocationLoading = false
          this.showLoading = false
        },
        { enableHighAccuracy: true },
      )
    },
    getCountStatistics() {
      queryStateCount(undefined, this.productId).then().then(resp => {
        this.totalCount = resp.data.result
      })
      queryStateCount(this.$x.biz.DevState.ONLINE, this.productId).then().then(resp => {
        this.onlineCount = resp.data.result
      })
      queryStateCount(this.$x.biz.DevState.OFFLINE, this.productId).then().then(resp => {
        this.offlineCount = resp.data.result
      })
      queryStateCount(this.$x.biz.DevState.NOT_ACTIVE, this.productId).then().then(resp => {
        this.notActiveCount = resp.data.result
      })
    },
    searchHandle(page, params, done) {
      queryDevices(page.pageIndex - 1, page.pageSize, { name: this.deviceName, state: this.queryDeviceState }, this.productId).then(resp => {
        done(resp.data.result.data, resp.data.result.total)
      })
    },
    rowLocation(row) {
      if (this.showLocationLoading) {
        this.$xtoast.info('定位中，请稍后')
        return
      }
      this.showLocationLoading = true
      queyrGeoDevices({
        deviceId: row.id,
      }).then(resp => {
        this.showLocationLoading = false
        this.geoDevices = resp.data.result.features
        if (!this.geoDevices || this.geoDevices.length === 0) {
          this.showGeoDeviceInfo = false
          this.geoDevice = undefined
          this.$xtoast.warning(`设备（${row.name}）暂无定位信息！`)
          return
        }
        this.geoDevice = undefined
        this.geoDeviceClickHandle(this.geoDevices[0])
        // eslint-disable-next-line prefer-destructuring
        this.center.lng = this.geoDevices[0].geometry.coordinates[0]
        // eslint-disable-next-line prefer-destructuring
        this.center.lat = this.geoDevices[0].geometry.coordinates[1]
      })
    },
    geoDeviceClickHandle(device) {
      if (this.geoDevice && device.properties.id === this.geoDevice.properties.id) {
        this.showGeoDeviceInfo = !this.showGeoDeviceInfo
        return
      }
      this.geoDevice = device
      // eslint-disable-next-line prefer-destructuring
      this.geoDevicePosition.lng = device.geometry.coordinates[0]
      // eslint-disable-next-line prefer-destructuring
      this.geoDevicePosition.lat = device.geometry.coordinates[1]
      // // eslint-disable-next-line prefer-destructuring
      // this.center.lng = device.geometry.coordinates[0]
      // // eslint-disable-next-line prefer-destructuring
      // this.center.lat = device.geometry.coordinates[1]
      this.showGeoDeviceInfo = true
    },
  },
}
</script>

<style lang="scss" scoped>
  @import '~@core/scss/base/bootstrap-extended/include';
  @import '~@core/scss/base/components/include';
  .location-container {
    width: 100%;
    height: 80vh;
    .bm-view {
      width: 100%;
      height: 100%;
    }
    .suspension {
      background-color: transparent;
      position: absolute;
      &.center {
        margin: 0 auto;
        left: 0;
        right: 0
      }
      &.top {
        top: 0;
      }
      &.right {
        top: 0;
        right: 0;
      }
      &.bottom {
        bottom: 0;
      }
      &-content {
        background-color: white;
        box-shadow: 10px 10px 10px 4px rgba(#000, 0.2);
        border-radius: 0.6rem;
      }
      &-location {
        @extend .suspension-content;
        width: 14rem;
        height: 3.6rem;
      }
      &-search{
        @extend .suspension-content;
        flex: 1;
        height: 3.6rem;
      }
      &-statistics{
        @extend .suspension-content;
        height: 3.6rem;
      }
    }
    .ctrl-panel {
      background-color: white;
      /*right: 2.4rem;*/
      width: 35%;
      border-radius: 0.6rem;
      /*overflow-scrolling: auto;*/
      box-shadow: 10px 10px 10px 4px rgba(#000, 0.2);
      right: -35%;
      padding: 0;
      z-index: 1051;
      position: fixed;
      transition: right 0.4s cubic-bezier(0.05, 0.74, 0.2, 0.99);
      backface-visibility: hidden;
      border-left: 1px solid rgba($black, 0.05);
      &.open {
        box-shadow: 10px 10px 10px 4px rgba(#000, 0.2);
        right: 0;
      }
      &-toggle {
        background: $primary;
        color: $white !important;
        display: block;
        box-shadow: 6px 10px 10px 4px rgba(#000, 0.2);
        border-radius: 6px;
        position: absolute;
        top: 50%;
        width: 38px;
        height: 38px;
        left: -49px;
        text-align: center;
        line-height: 40px;
        cursor: pointer;
      }
    }
  }
  .dark-layout {
    .location-container {
      .suspension {
        &-content,&-search,&-statistics {
          background-color: $theme-dark-card-bg;
        }
      }
      .ctrl-panel {
        background-color: $theme-dark-card-bg;
        box-shadow: 10px 10px 10px 4px rgba(#000, 0.6);
        &-toggle {
          background: $theme-dark-card-bg;
          box-shadow: 6px 10px 10px 4px rgba(#000, 0.6);
        }
      }
    }
  }
</style>
