<template>
  <div>
    <b-row>
      <!-- card actions -->
      <b-col cols="12">
        <b-card
          ref="cardAction"
        >
          <b-card-title class="d-flex ">
            <div class="x-indicator mr-50 x-text-bold" />
            <div class="d-flex align-items-center x-text-bold ">
              产品信息
              <feather-icon
                class="ml-4 text-primary x-link"
                icon="Edit3Icon"
                size="18"
                v-b-tooltip.hover.top="'编辑产品信息'"
                @click="showProductInfoForm = !showProductInfoForm"
              />
            </div>
          </b-card-title>
          <div>
            <table
              class="align-content-center text-center viewTable"
            >
              <tr>
                <th>
                  所属品类
                </th>
                <td>
                  {{ categoryName }}
                </td>
                <th>
                  所属机构
                </th>
                <td>
                  {{ orgName }}
                </td>
                <th>
                  节点类型
                </th>
                <td>
                  <b-badge variant="light-primary">
                    {{ product.deviceType.text ? product.deviceType.text : '直连设备' }}
                  </b-badge>
                </td>
              </tr>
              <tr>
                <th>
                  接入协议
                </th>
                <td>
                  <b-badge variant="light-primary">
                    {{ protocolName }}
                  </b-badge>
                </td>
                <th>
                  连接协议
                </th>
                <td>
                  <b-badge variant="light-primary">
                    {{ product.transportProtocol }}
                  </b-badge>
                </td>
                <th>
                  创建时间
                </th>
                <td>
                  {{ product.createTime ? product.createTime.cxFormat() : '/' }}
                </td>
              </tr>
              <tr>
                <th>
                  说明
                </th>
                <td
                  colspan="5"
                  class="text-left pl-1 pr-1">
                  {{ product.describe }}
                </td>
              </tr>
            </table>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-row>

      <!-- card actions -->
      <b-col
        v-if="configs.length > 0"
        cols="12"
      >
        <b-card
          ref="cardAction"
        >
          <b-card-title class="d-flex x-text-bold">
            <div class="x-indicator mr-50" />
            产品配置
            <feather-icon
              v-b-tooltip.hover.top="'编辑产品配置'"
              class="ml-4 text-primary x-link" icon="SettingsIcon" size="18"
              @click="showProductConfigForm = !showProductConfigForm"
            />
          </b-card-title>
          <b-alert v-if="showAlert" variant="warning" class="x-anim-fade-in" show>
            <x-popconfirm
              target="config-alert"
              content="确定要重新应用配置？"
              triggers="click"
              @enter="deploy">
            </x-popconfirm>
            <div class="alert-body">
              <span class="el-icon-warning font-medium-1"></span>
              修改配置后请重新<span id="config-alert" class="ml-50 mr-50 x-link">应用配置</span>才能生效
            </div>
          </b-alert>
          <div
            v-for="(config, index) in configs"
            :key="index"
          >
            <h5>{{ config.name }}</h5>
            <b-alert
              variant="primary"
              :show="!!config.description "
            >
              <div class="alert-body">
                <span class="el-icon-info font-medium-1 mr-50"></span>
                <span>{{ config.description }}</span>
              </div>
            </b-alert>
            <b-row cols="12">
              <b-col cols="12">
                <table class="align-content-center text-center pzTable">
                  <tr
                    v-for="(prop, index) in config.properties"
                    :key="index"
                  >
                    <th>
                      <div class="d-flex align-items-center justify-content-center">
                        <span>{{ prop.name }}</span>
                        <span
                          v-b-tooltip.hover.top="prop.description"
                          class="el-icon-question ml-50 text-secondary text-lighten-5 "
                        />
                      </div>
                    </th>
                    <td>
                      {{ prop.value }}
                      <b-button
                        v-if="prop.value"
                        v-ripple.400="$x.ripple.primary"
                        v-clipboard:copy="prop.value"
                        v-clipboard:success="$x.onCopy"
                        v-clipboard:error="$x.onError"
                        variant="flat-primary"
                        size="sm"
                        class="x-link btn-icon rounded-circle x-transparent">
                        <feather-icon
                          icon="CopyIcon"
                        />
                      </b-button>
                    </td>
                  </tr>
                </table>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-sidebar
      ref="slider"
      :visible="showProductInfoForm"
      idebar-class="sidebar-lg"
      bg-variant="white"
      right
      backdrop
      shadow
      width="40%"
      @hidden="hideInfoForm"
    >
      <product-form
        ref="productInfoForm"
        :product="product"
        :is-new="false"
        :is-view="false"
        :is-redirect="false"
        :category-list="categoryList"
        :orgin-list="orginList"
        :protocol-list="protocolList"
        :polices-list="policesList"
        @editDone="editInfoDone"
      />
    </b-sidebar>

    <b-sidebar
      ref="slider"
      :visible="showProductConfigForm"
      idebar-class="sidebar-lg"
      bg-variant="white"
      right
      backdrop
      shadow
      width="40%"
      @hidden="hideConfigForm"
    >
      <product-config-form
        ref="productInfoForm"
        :productId="product.id"
        :data="product.configuration"
        :configs="configs"
        @editDone="editConfigDone"
      />
    </b-sidebar>
  </div>
</template>
<script>
import {
  BCard, BRow, BCol, BBadge, VBToggle, BSidebar,
  BCardTitle, VBTooltip, BAlert, BButton,
} from 'bootstrap-vue'
import XPopconfirm from '@core/components/cx/popconfirm/XPopconfirm.vue'
import {
  queryCategory, queryOrgin, queryProtocol, queryPolicies,
} from '@/api/device-manage/product-list'
import ProductForm from './ProductForm.vue'
import ProductConfigForm from './ProductConfigForm.vue'

export default {
  components: {
    BAlert,
    BCard,
    BRow,
    BCol,
    BBadge,
    BSidebar,
    BCardTitle,
    BButton,
    XPopconfirm,
    ProductForm,
    ProductConfigForm,
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-toggle': VBToggle,
  },
  props: {
    product: {
      type: Object,
      default: () => {},
    },
    configs: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    categoryName() {
      for (let category of this.categoryList) {
        if (category.id === this.product.classifiedId) {
          return category.name
        }
      }
      return ''
    },
    orgName() {
      for (let org of this.orginList) {
        if (org.id === this.product.orgId) {
          return org.name
        }
      }
      return ''
    },
    protocolName() {
      for (let protocol of this.protocolList) {
        if (protocol.id === this.product.messageProtocol) {
          return protocol.name
        }
      }
      return ''
    },
  },
  data() {
    return {
      showProductInfoForm: false,
      showProductConfigForm: false,
      categoryList: [],
      orginList: [],
      protocolList: [],
      policesList: [],
      count: 0,
      showAlert: false,
    }
  },
  created() {
    queryCategory().then(resp => {
      this.categoryList = resp.data.result
    })
    queryOrgin().then(resp => {
      this.orginList = resp.data.result
    })
    queryProtocol().then(resp => {
      this.protocolList = resp.data.result
    })
    queryPolicies().then(resp => {
      this.policesList = resp.data.result
    })
  },
  methods: {
    hideInfoForm() {
      this.showProductInfoForm = false
    },
    editInfoDone() {
      this.$emit('editDone')
      this.showProductInfoForm = false
    },
    hideConfigForm() {
      this.showProductConfigForm = false
    },
    editConfigDone() {
      this.$emit('editDone')
      this.showProductConfigForm = false
      this.showAlert = true
    },
    deploy() {
      this.$emit('deploy', () => {
        this.showAlert = false
      })
    },
  },
}
</script>
<style>
</style>
