var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"refFormObserver"},[_c('b-form',{staticClass:"m-2"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',[_c('label',[_c('span',{staticClass:"x-require-indicator font-medium-2 x-text-bold"},[_vm._v("*")]),_c('span',{staticClass:"font-medium-1 x-text-bold text-body"},[_vm._v("产品ID：")])]),_c('validation-provider',{attrs:{"name":"产品ID","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"请输入产品ID","readonly":!_vm.isNew},model:{value:(_vm.formData.id),callback:function ($$v) {_vm.$set(_vm.formData, "id", $$v)},expression:"formData.id"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v("产品ID不能为空")]):_vm._e()]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',[_c('label',[_c('span',{staticClass:"x-require-indicator font-medium-2 x-text-bold"},[_vm._v("*")]),_c('span',{staticClass:"font-medium-1 x-text-bold text-body"},[_vm._v("产品名称：")])]),_c('validation-provider',{attrs:{"name":"产品名称","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"请输入产品名称","readonly":_vm.isView},model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v("产品名称不能为空")]):_vm._e()]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',[_c('label',[_c('span',{staticClass:"x-require-indicator font-medium-2 x-text-bold"},[_vm._v("*")]),_c('span',{staticClass:"font-medium-1 x-text-bold text-body"},[_vm._v("所属品类：")])]),_c('validation-provider',{attrs:{"name":"所属品类","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.categoryList,"placeholder":"请选择所属品类","state":errors.length > 0 ? false:null,"label":"name","reduce":function (option) { return option.id; },"disabled":_vm.isView},model:{value:(_vm.formData.classifiedId),callback:function ($$v) {_vm.$set(_vm.formData, "classifiedId", $$v)},expression:"formData.classifiedId"}},[_c('template',{slot:"no-options"},[_vm._v(_vm._s(_vm.$t('noData')))])],2),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v("所属品类不能为空")]):_vm._e()]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',[_c('label',[_c('span',{staticClass:"font-medium-1 x-text-bold text-body"},[_vm._v("所属机构：")])]),_c('validation-provider',{attrs:{"name":"所属机构"}},[_c('v-select',{attrs:{"options":_vm.orginList,"placeholder":"请选择所属机构","label":"name","reduce":function (option) { return option.id; },"disabled":_vm.isView},model:{value:(_vm.formData.orgId),callback:function ($$v) {_vm.$set(_vm.formData, "orgId", $$v)},expression:"formData.orgId"}},[_c('template',{slot:"no-options"},[_vm._v(_vm._s(_vm.$t('noData')))])],2)],1)],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',[_c('label',[_c('span',{staticClass:"x-require-indicator font-medium-2 x-text-bold"},[_vm._v("*")]),_c('span',{staticClass:"font-medium-1 x-text-bold text-body"},[_vm._v("接入协议：")])]),_c('validation-provider',{attrs:{"name":"接入协议","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.protocolList,"placeholder":"请选择接入协议","state":errors.length > 0 ? false:null,"label":"name","reduce":function (option) { return option.id; },"disabled":_vm.isView},model:{value:(_vm.formData.messageProtocol),callback:function ($$v) {_vm.$set(_vm.formData, "messageProtocol", $$v)},expression:"formData.messageProtocol"}},[_c('template',{slot:"no-options"},[_vm._v(_vm._s(_vm.$t('noData')))])],2),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v("接入协议不能为空")]):_vm._e()]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',[_c('label',[_c('span',{staticClass:"x-require-indicator font-medium-2 x-text-bold"},[_vm._v("*")]),_c('span',{staticClass:"font-medium-1 x-text-bold text-body"},[_vm._v("连接协议：")])]),_c('validation-provider',{attrs:{"name":"连接协议","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.protocolTransportsList,"placeholder":"请选择连接协议","state":errors.length > 0 ? false:null,"label":"name","reduce":function (option) { return option.id; },"disabled":_vm.isView},model:{value:(_vm.formData.transportProtocol),callback:function ($$v) {_vm.$set(_vm.formData, "transportProtocol", $$v)},expression:"formData.transportProtocol"}},[_c('template',{slot:"no-options"},[_vm._v(_vm._s(_vm.$t('noData')))])],2),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(" 连接协议不能为空 ")]):_vm._e()]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',[_c('label',[_c('span',{staticClass:"font-medium-1 x-text-bold text-body"},[_vm._v("存储策略：")])]),_c('validation-provider',{attrs:{"name":"存储策略"}},[_c('v-select',{attrs:{"options":_vm.policesList,"placeholder":"请选择存储策略, 默认ES-列式存储","label":"name","reduce":function (option) { return option.id; },"disabled":_vm.isView},model:{value:(_vm.formData.storePolicy),callback:function ($$v) {_vm.$set(_vm.formData, "storePolicy", $$v)},expression:"formData.storePolicy"}},[_c('template',{slot:"no-options"},[_vm._v(_vm._s(_vm.$t('noData')))])],2)],1)],1)],1),(false)?_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',[_c('label',[_c('span',{staticClass:"x-require-indicator font-medium-2 x-text-bold"},[_vm._v("*")]),_c('span',{staticClass:"font-medium-1 x-text-bold text-body"},[_vm._v("节点类型：")])]),_c('validation-provider',{attrs:{"name":"节点类型","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-radio-group',{attrs:{"options":_vm.options,"value-field":"value","text-field":"text","disabled-field":"disabled"},model:{value:(_vm.formData.deviceType.value),callback:function ($$v) {_vm.$set(_vm.formData.deviceType, "value", $$v)},expression:"formData.deviceType.value"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v("节点类型不能为空")]):_vm._e()]}}],null,false,2160972813)})],1)],1):_vm._e(),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',[_c('label',[_c('span',{staticClass:"font-medium-1 x-text-bold text-body"},[_vm._v("描述：")])]),_c('b-form-textarea',{attrs:{"id":"textarea-default","placeholder":"请输入设备描述","rows":"3","readonly":_vm.isView},model:{value:(_vm.formData.describe),callback:function ($$v) {_vm.$set(_vm.formData, "describe", $$v)},expression:"formData.describe"}})],1)],1),_c('b-col',{staticClass:"mt-1",attrs:{"cols":"12"}},[_c('b-button',{attrs:{"variant":"primary","type":"submit","block":"","hidden":_vm.isView},on:{"click":function($event){$event.preventDefault();return _vm.validationForm($event)}}},[_vm._v(" 确定 ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }