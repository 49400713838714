<template>
  <b-tabs
    vertical
    active-nav-item-class="bg-light-primary nav-vertical-w justify-content-start x-anim-fade-in"
    nav-wrapper-class="nav-vertical">
    <b-tab title="设备列表" active>
      <x-table
        tableType="list"
        :options="options"
        hide-search-bar
        @search="searchHandle"
        @rowEdit="rowEdit"
        @rowAdd="rowAdd"
        @rowEnable="rowEnable"
        @rowDisable="rowDisable"
        @rowDelete="rowDelete">
        <template slot="form-productName">
          <b-form-input v-model="productName" disabled></b-form-input>
        </template>
      </x-table>
    </b-tab>
    <b-tab title="设备分布" lazy>
      <location :pid="productId"></location>
    </b-tab>
  </b-tabs>
</template>
<script>
import {
  query, deploy, unDeploy, add, edit, remove,
} from '@/api/device-manage/device-list'
import {
  BTabs, BTab, BFormInput,
} from 'bootstrap-vue'
import XTable from '@core/components/cx/table/XTable.vue'
import Location from '@/views/ops/location/index.vue'

export default {
  components: {
    XTable,
    BTabs,
    BTab,
    BFormInput,
    Location,
  },
  directives: {
  },
  props: {
    productId: {
      type: String,
      default: undefined,
    },
    productName: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      options: {
        exportBtn: false,
        printBtn: false,
        hideStyleSwitch: true,
        actions: [{ action: 'view', name: '查看', icon: 'EyeIcon', url: '/device-center/device/view/{id}', tip: '查看设备详情' },
          { action: 'edit', name: '编辑', icon: 'EditIcon', tip: '编辑' },
          { action: 'enable', name: '启用', icon: 'PlayIcon', tip: '启用' },
          { action: 'disable', name: '禁用', icon: 'SlashIcon', tip: '禁用' },
          { action: 'delete', name: '删除', icon: 'Trash2Icon', tip: '删除' },
        ],
        actionFilter: (action, data) => {
          if (data.state.value === this.$x.biz.DevState.ONLINE || data.state.value === this.$x.biz.DevState.OFFLINE) {
            return action !== 'enable'
          }
          return action !== 'disable'
        },
        collapseExpandAll: true,
        columns: [{
          label: '设备ID',
          prop: 'id',
          sortable: true,
          editDisable: true,
          rules: {
            rule: 'required',
          },
        }, {
          label: '设备名称',
          prop: 'name',
          sortable: false,
          rules: {
            rule: 'required',
          },
        }, {
          label: '产品',
          prop: 'productName',
          sortable: false,
          addDisable: true,
          editDisable: true,
          rules: {
            rule: 'required',
          },
        }, {
          label: '所属机构',
          prop: 'orgId',
          type: 'select',
          dictUrl: '/organization/_all',
          props: { label: 'name', value: 'id' },
          rowShow: false,
        }, {
          label: '描述',
          prop: 'describe',
          type: 'textarea',
          rowShow: false,
        }, {
          label: '状态',
          prop: 'state',
          type: 'select',
          dictData: this.$x.biz.DevState.DictData,
          props: {
            label: 'text',
            value: 'value',
          },
          rowSelectDot: true,
          selectVariant: prop => {
            if (prop.value === this.$x.biz.DevState.ONLINE) {
              return 'text-success'
            }
            if (prop.value === this.$x.biz.DevState.OFFLINE) {
              return 'text-danger'
            }
            return 'text-warning'
          },
          sortable: false,
          addShow: false,
          editShow: false,
        }, {
          label: '创建时间',
          prop: 'createTime',
          type: 'datetimerange',
          sortable: true,
          addShow: false,
          editShow: false,
        }],
      },
    }
  },
  methods: {
    searchHandle(page, params, done) {
      query(page.pageIndex - 1, page.pageSize, null, this.productId).then(resp => {
        done(resp.data.result.data, resp.data.result.total)
      })
    },
    rowAdd(data, done) {
      data.productId = this.productId
      data.productName = this.productName
      data.state = this._.cloneDeep(this.$x.biz.DevState.DictData[0])
      this.$delete(data, 'createTime')
      add(data).then(() => {
        done()
      })
    },
    rowEdit(data, done) {
      this.$delete(data, 'createTime')
      this.$delete(data, 'creatorId')
      this.$delete(data, 'creatorName')
      edit(data).then(() => {
        done()
      })
    },
    rowEnable(data, done) {
      deploy(data.id).then(() => {
        done()
      })
    },
    rowDisable(data, done) {
      unDeploy(data.id).then(() => {
        done()
      })
    },
    rowDelete(data, done) {
      remove(data.id).then(() => {
        done()
      })
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
