<template>
  <validation-observer ref="refFormObserver">
    <b-form class="m-2">
      <b-row>
        <b-col cols="12">
          <b-form-group>
            <label >
              <span class="x-require-indicator font-medium-2 x-text-bold">*</span>
              <span class="font-medium-1 x-text-bold text-body">产品ID：</span>
            </label>
            <validation-provider
              #default="{ errors }"
              name="产品ID"
              rules="required"
            >
              <b-form-input
                v-model="formData.id"
                :state="errors.length > 0 ? false:null"
                placeholder="请输入产品ID"
                :readonly="!isNew"
              />
              <small class="text-danger" v-if="errors[0]">产品ID不能为空</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group>
            <label >
              <span class="x-require-indicator font-medium-2 x-text-bold">*</span>
              <span class="font-medium-1 x-text-bold text-body">产品名称：</span>
            </label>
            <validation-provider
              #default="{ errors }"
              name="产品名称"
              rules="required"
            >
              <b-form-input
                v-model="formData.name"
                :state="errors.length > 0 ? false:null"
                placeholder="请输入产品名称"
                :readonly="isView"
              />
              <small class="text-danger" v-if="errors[0]">产品名称不能为空</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group>
            <label >
              <span class="x-require-indicator font-medium-2 x-text-bold">*</span>
              <span class="font-medium-1 x-text-bold text-body">所属品类：</span>
            </label>
            <validation-provider
              #default="{ errors }"
              name="所属品类"
              rules="required"
            >
              <v-select
                v-model="formData.classifiedId"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="categoryList"
                placeholder="请选择所属品类"
                :state="errors.length > 0 ? false:null"
                label="name"
                :reduce="option => option.id"
                :disabled="isView"
              >
                <template slot="no-options">{{$t('noData')}}</template>
              </v-select>
              <small class="text-danger" v-if="errors[0]">所属品类不能为空</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group>
            <label >
              <span class="font-medium-1 x-text-bold text-body">所属机构：</span>
            </label>
            <validation-provider
              name="所属机构"
            >
              <v-select
                v-model="formData.orgId"
                :options="orginList"
                placeholder="请选择所属机构"
                label="name"
                :reduce="option => option.id"
                :disabled="isView"
              >
                <template slot="no-options">{{$t('noData')}}</template>
              </v-select>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group>
            <label >
              <span class="x-require-indicator font-medium-2 x-text-bold">*</span>
              <span class="font-medium-1 x-text-bold text-body">接入协议：</span>
            </label>
            <validation-provider
              #default="{ errors }"
              name="接入协议"
              rules="required"
            >
              <v-select
                v-model="formData.messageProtocol"
                :options="protocolList"
                placeholder="请选择接入协议"
                :state="errors.length > 0 ? false:null"
                label="name"
                :reduce="option => option.id"
                :disabled="isView"
              >
                <template slot="no-options">{{$t('noData')}}</template>
              </v-select>
              <small class="text-danger" v-if="errors[0]">接入协议不能为空</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group>
            <label >
              <span class="x-require-indicator font-medium-2 x-text-bold">*</span>
              <span class="font-medium-1 x-text-bold text-body">连接协议：</span>
            </label>
            <validation-provider
              #default="{ errors }"
              name="连接协议"
              rules="required"
            >
              <v-select
                v-model="formData.transportProtocol"
                :options="protocolTransportsList"
                placeholder="请选择连接协议"
                :state="errors.length > 0 ? false:null"
                label="name"
                :reduce="option => option.id"
                :disabled="isView"
              >
                <template slot="no-options">{{$t('noData')}}</template>
              </v-select>
              <small
                class="text-danger"
                v-if="errors[0]"
              >
                连接协议不能为空
              </small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group>
            <label >
              <span class="font-medium-1 x-text-bold text-body">存储策略：</span>
            </label>
            <validation-provider
              name="存储策略"
            >
              <v-select
                v-model="formData.storePolicy"
                :options="policesList"
                placeholder="请选择存储策略, 默认ES-列式存储"
                label="name"
                :reduce="option => option.id"
                :disabled="isView"
              >
                <template slot="no-options">{{$t('noData')}}</template>
              </v-select>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="12" v-if="false">
          <b-form-group>
            <label >
              <span class="x-require-indicator font-medium-2 x-text-bold">*</span>
              <span class="font-medium-1 x-text-bold text-body">节点类型：</span>
            </label>
            <validation-provider
              name="节点类型"
              #default="{ errors }"
              rules="required"
            >
              <b-form-radio-group
                v-model="formData.deviceType.value"
                :options="options"
                value-field="value"
                text-field="text"
                disabled-field="disabled"
              />
              <small class="text-danger" v-if="errors[0]">节点类型不能为空</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group>
            <label >
              <span class="font-medium-1 x-text-bold text-body">描述：</span>
            </label>
            <b-form-textarea
              id="textarea-default"
              v-model="formData.describe"
              placeholder="请输入设备描述"
              rows="3"
              :readonly="isView"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          class="mt-1">
          <b-button
            variant="primary"
            type="submit"
            block
            @click.prevent="validationForm"
            :hidden="isView"
          >
            确定
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import {
  protocolTransports, add, edit,
} from '@/api/device-manage/product-list'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BButton, BFormTextarea, BFormRadioGroup,
} from 'bootstrap-vue'
import {
  required,
} from '@validations'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormTextarea,
    vSelect,
    BFormRadioGroup,
  },
  props: {
    isNew: {
      type: Boolean,
      default: true,
    },
    isView: {
      type: Boolean,
      default: false,
    },
    product: {
      type: Object,
      default: () => {},
    },
    categoryList: {
      type: Array,
      default: () => [],
    },
    orginList: {
      type: Array,
      default: () => [],
    },
    protocolList: {
      type: Array,
      default: () => [],
    },
    policesList: {
      type: Array,
      default: () => [],
    },
    isRedirect: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      formData: {
        id: undefined,
        classifiedId: undefined,
        classifiedName: undefined,
        describe: undefined,
        name: undefined,
        orgId: undefined,
        messageProtocol: undefined,
        transportProtocol: undefined,
        protocolName: undefined,
        deviceType: { text: '直连设备', value: 'device' },
        storePolicy: undefined,
      },
      options: this.$x.biz.DeviceType,
      messageProtocol: undefined,
      protocolTransportsList: undefined,
      required,
    }
  },
  watch: {
    product(obj) {
      this.formData = this._.cloneDeep(obj)
      this.formData.transportProtocol = obj.transportProtocol
      this.formData.deviceType = obj.deviceType
    },
    'formData.messageProtocol': {
      handler(val, oldVal) {
        if (oldVal === undefined || val !== oldVal) {
          if (val === null || val === undefined) {
            this.protocolTransportsList = []
          } else {
            protocolTransports(val).then(res => {
              this.protocolTransportsList = res.data.result
            })
          }
        }
        this.formData.messageProtocol = val
      },
      deep: true,
    },
  },
  methods: {
    validationForm() {
      this.$refs.refFormObserver.validate().then(success => {
        if (success) {
          if (this.isNew) {
            add(this.formData).then(() => {
              this.$bvToast.toast('操作成功', {
                title: '产品新增',
                variant: 'primary',
                solid: true,
              })
              if (this.isRedirect) {
                this.$router.push({
                  name: 'ec-product-view',
                  params: { id: this.formData.id },
                })
              }
            })
          } else {
            edit(this.formData).then(() => {
              this.$xtoast.success('编辑成功')
              this.$emit('editDone')
              if (this.isRedirect) {
                this.$router.push({
                  name: 'ec-product-view',
                  params: { id: this.formData.id },
                })
              }
            })
          }
        }
      })
    },
    hide() {
      this.$refs.refFormObserver.reset()
      this.formData = {
        id: undefined,
        classifiedId: undefined,
        describe: undefined,
        name: undefined,
        orgId: undefined,
        messageProtocol: undefined,
        transportProtocol: undefined,
        protocolName: undefined,
        deviceType: this.$x.biz.DeviceType.DEVICE,
        storePolicy: undefined,
      }
      this.protocolTransportsList = undefined
    },
  },
}
</script>

<style lang="scss">
  .x-require-indicator{
    margin-right: .2rem;
    color: red;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
  }
</style>
