import request from '@/auth/jwt/useJwt'

/*  查询设备列表  */
// eslint-disable-next-line arrow-body-style
export function query(pageIndex, pageSize, params) {
  console.log('2223', params)
  let requestUrl = `protocol/_query?pageIndex=${pageIndex}&pageSize=${pageSize}`
  if (params !== null) {
    let index = 0
    if (params.name) {
      requestUrl += `&terms[${index}].column=name&terms[${index}].value=${params.name}`
      index++
    }
    if (params.type) {
      requestUrl += `&terms[${index}].column=type&terms[${index}].value=${params.type}`
      index++
    }
    if (params.state || params.state === 0) {
      requestUrl += `&terms[${index}].column=state&terms[${index}].value=${params.state}`
    }
  }
  return request.axiosIns({
    url: requestUrl,
    method: 'get',
  })
}
/* 发布、重新发布 */
export function deploy(id) {
  return request.axiosIns({
    url: `protocol/${id}/_deploy`,
    method: 'post',
  })
}
/* 取消发布 */
export function unDeploy(id) {
  return request.axiosIns({
    url: `protocol/${id}/_un-deploy`,
    method: 'post',
  })
}
/* 删除协议 */
export function remove(id) {
  return request.axiosIns({
    url: `protocol/${id}`,
    method: 'DELETE',
  })
}
/* 新增协议 */
export function add(data) {
  return request.axiosIns({
    url: 'protocol/',
    method: 'PATCH',
    data,
  })
}
/* 查询 */
export function queryById(id) {
  return request.axiosIns({
    url: `protocol/${id}`,
    method: 'GET',
  })
}
