<template>
  <bm-overlay
    ref="customOverlay"
    :class="{sample: true, active}"
    pane="labelPane"
    @draw="draw"
  >
    <b-overlay
      variant="light"
      :show="showLoading"
      spinner-variant="primary"
      opacity=".75"
      rounded="sm">
      <b-card class="info-window pl-1 pr-1"  >
        <div  v-if="device" class="d-flex flex-column w-100">
          <div class="d-flex w-100">
            <b-avatar class="mt-50" size="4rem" rounded :src="require('@/assets/images/cx/device.svg')" text="CX" variant="light-primary">
            </b-avatar>
            <div class="d-flex flex-column ml-2" style="width: 75%">
              <div class="w-100 d-flex basic justify-content-between align-items-center d-flex" >
                <span class="text-primary x-text-cut x-text-bold">{{device.name}}</span>
                <div class="d-flex align-items-center">
                  <div class="font-medium-3 mr-50" :class="getDeviceStateTag()">●</div>
                  <span class="text-dark">{{device.state.text}}</span>
                </div>
              </div>
              <div class="d-flex">
                <span class=" text-secondary w-50 x-text-cut">{{ 'ID: ' + device.id }}</span>
                <span class=" text-secondary w-50 x-text-cut ml-50">{{ '产品: ' + device.productName }}</span>
              </div>
            </div>
          </div>
          <b-row cols="12">
            <b-col cols="12" class="text-secondary d-flex align-items-center mb-50 mt-50">
              <span class="el-icon-location font-medium-1 mr-50"></span>{{address}}</b-col>
            <b-col cols="4" v-for="(prop, index) in properties" :key="index" class="p-0" >
              <div class="prop-value m-50 p-50 rounded">
                <div v-tooltip.hover.bottom="`${prop.name} ( ${prop.id} ) `"
                  class="text-secondary x-text-cut font-small-3">{{ prop.name + ' ( '+ prop.id +' ) ' }}</div>
                <div v-tooltip.hover.bottom="`${getPropertyValue(prop.id)}`"
                  class="x-text-bold text-dark x-text-cut mt-50 font-medium-2">{{getPropertyValue(prop.id)}}</div>
              </div>
            </b-col>
            <b-col cols="4" v-for="(eve, index2) in events" :key="index2 + 100000" class="p-0" >
              <div class="prop-value m-50 p-50 rounded">
                <div v-tooltip.hover.bottom="`${eve.name} ( ${eve.id} ) `"
                  class="text-secondary x-text-cut font-small-3">
                  <span class="font-medium-3" :class="getEventLevelTag(eve)">●</span>
                  {{ eve.name + ' ( '+ eve.id +' ) ' }}
                </div>
                <div v-tooltip.hover.bottom="`${eve.__totalCount}次`"
                  class="x-text-bold text-dark x-text-cut mt-50 font-medium-2">{{ eve.__totalCount + '次' }}</div>
              </div>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </b-overlay>
  </bm-overlay>
</template>

<script>
import {
  BOverlay, BCard, BAvatar, BRow, BCol,
} from 'bootstrap-vue'
import {
  getById, getEventCount, multi,
} from '@/api/device-manage/device-list'

export default {
  components: {
    BOverlay,
    BCard,
    BAvatar,
    BRow,
    BCol,
  },
  props: {
    position: {
      type: Object,
      default: () => {},
    },
    active: {
      type: Boolean,
      default: false,
    },
    deviceId: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      showLoading: false,
      device: undefined,
      address: undefined,
      properties: [],
      runningStates: undefined,
      events: [],
    }
  },
  watch: {
    position: {
      handler() {
        this.$refs.customOverlay.reload()
      },
      deep: true,
    },
  },
  methods: {
    draw({ el, BMap, map }) {
      const { lng, lat } = this.position
      const pixel = map.pointToOverlayPixel(new BMap.Point(lng, lat))
      el.style.left = pixel.x + 'px'
      el.style.top = pixel.y + 'px'
      this.getDeviceInfo()
    },
    getDeviceInfo() {
      if (this.device && this.deviceId === this.device.id) {
        return
      }
      const { lng, lat } = this.position
      const point = new global.BMap.Point(lng, lat)
      const gc = new global.BMap.Geocoder()
      gc.getLocation(point, rs => {
        this.address = rs.address
      })
      this.showLoading = true
      getById(this.deviceId).then(resp => {
        this.device = resp.data.result
        this.showLoading = false
        const { properties, events } = JSON.parse(resp.data.result.metadata)
        this.properties = properties
        this.events = events
        if (this.properties) {
          let runningStates = new Map()
          this.properties.forEach(item => {
            runningStates.set(item.id, {
              value: '',
              formatValue: '',
            })
          })
          multi(this.device.id, this.device.productId, 1).then(res => {
            if (res.data.result && res.data.result.length > 0) {
              res.data.result.forEach(obj => {
                const runningState = runningStates.get(obj.data.value.property)
                runningState.value = obj.data.value.value
                runningState.formatValue = obj.data.value.formatValue
              })
            }
            this.runningStates = runningStates
          })
        }
        if (this.events) {
          this.events.forEach(item => {
            item.__totalCount = 0
            getEventCount(this.device.id, item.id).then(resp2 => {
              item.__totalCount = resp2.data.result.total
            })
          })
        }
      })
    },
    getPropertyValue(prop) {
      if (!this.runningStates) {
        return '/'
      }
      if (this.runningStates.has(prop)) {
        let { formatValue } = this.runningStates.get(prop)
        if (formatValue && formatValue !== '') {
          return formatValue
        }
        let { value } = this.runningStates.get(prop)
        if (value !== null && value !== undefined && value !== '') {
          return value
        }
      }
      return '/'
    },
    getDeviceStateTag() {
      switch (this.device.state.value) {
        case this.$x.biz.DevState.NOT_ACTIVE:
          return 'text-warning'
        case this.$x.biz.DevState.OFFLINE:
          return 'text-danger'
        case this.$x.biz.DevState.ONLINE:
          return 'text-success'
        default:
          return 'text-primary'
      }
    },
    getEventLevelTag(event) {
      switch (event.expands.level) {
        case this.$x.datatype.eventLevels[0].value:
          return 'text-primary'
        case this.$x.datatype.eventLevels[1].value:
          return 'text-warning'
        case this.$x.datatype.eventLevels[2].value:
          return 'text-danger'
        default:
          return 'text-primary'
      }
    },
  },
}
</script>

<style lang="scss" scoped>
  .sample {
    background: transparent;
    /*overflow: hidden;*/
    /*box-shadow: 0 0 5px #000;*/
    /*color: #fff;*/
    /*text-align: center;*/
    /*padding: 10px;*/
    position: absolute;
  }
  .info-window {
    width: 30rem;
    overflow: hidden;
    .basic {
      width: 100%;
      height: 2.6rem;
    }
    .prop-value {
      background-color: #e6edf5 !important;
    }
  }
  .dark-layout {
    .info-window {
      .prop-value {
        background-color: #343d55 !important;
      }
    }
  }
</style>
