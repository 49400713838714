import request from '@/auth/jwt/useJwt'

export function init() {
}

/*  查询geo设备  */
export function queyrGeoDevices(params) {
  let data = {
    shape: {},
    filter: {
      where: 'objectType = device',
      pageSize: 1000,
    },
  }
  if (params) {
    if (params.productId) {
      data.filter.where += ` and tags.productId=${params.productId}`
    }
    if (params.deviceId) {
      data.filter.where += ` and objectId=${params.deviceId}`
    }
    if (params.deviceName) {
      data.filter.where += ` and tags.deviceName like %${params.deviceName}%`
    }
  }
  return request.axiosIns({
    url: '/geo/object/_search/geo.json',
    method: 'POST',
    data,
  })
}
