<template>
  <div>
    <b-alert
      variant="warning"
      show
    >
      <div class="alert-body">
        <span class="el-icon-warning font-medium-1 mr-50"></span>
        <span>目前仅展示脚本类型协议！</span>
      </div>
    </b-alert>
    <editor v-if="code"
            v-model="code">
    </editor>
  </div>
</template>
<script>
import {
  BAlert,
} from 'bootstrap-vue'
import Editor from '@core/components/cx/code-editor/editor.vue'

export default {
  components: {
    BAlert,
    Editor,
  },
  props: {
    code: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      protocol: undefined,
    }
  },
  created() {
  },
  methods: {
  },
}
</script>
<style>
</style>
