<template>
  <b-overlay
    variant="light"
    :show="showLoading"
    spinner-variant="primary"
    opacity=".75"
    rounded="sm"
  >
    <b-card class="mb-2">
      <b-row class="d-flex justify-item-center align-items-center ">
        <b-col md="1">
          <feather-icon
            size="30"
            class="text-primary x-link"
            icon="ChevronLeftIcon"
            @click="back()"
          />
        </b-col>
        <b-col md="8" class="d-flex flex-row align-items-center">
          <b-avatar
            v-viewer
            rounded
            size="lg"
            :src="product.photoUrl"
            text="CX" variant="light-primary"
          />
          <span class="ml-1 x-text-bold font-large-1">{{ product.name }}</span>
        </b-col>
        <b-col md="3" >
          <b-button
            v-ripple.400="$x.ripple.danger"
            variant="danger"
            pill
            size="sm"
            class="mr-1"
            @click="deployProduct('配置成功')">
            <feather-icon
              icon="DownloadIcon"/>
            <span class="ml-50 ">应用配置</span>
          </b-button>
          <b-button
            v-ripple.400="$x.ripple.primary"
            variant="primary"
            pill
            size="sm"
            @click="refresh">
            <feather-icon
              icon="RefreshCwIcon"/>
            <span class="ml-50 ">刷新</span>
          </b-button>
        </b-col>
        <b-col cols="1" class="mt-1" v-if="product.describe">
        </b-col>
        <b-col
          cols="10"
          class="d-flex mt-1"
          v-if="product.describe">
          <b-alert
            variant="none"
            show
          >
            <div class="alert-body text-secondary">
              <feather-icon
                class="mr-50"
                icon="FeatherIcon"
                size="16"
              />
              <span>{{ product.describe }}</span>
            </div>
          </b-alert>
        </b-col>
      </b-row>
      <b-row class="d-flex justify-item-center align-items-center mt-50">
        <b-col cols="1">
        </b-col>
        <b-col md="3">
          <span class="text-muted">产品ID：</span>
          <span class="ml-1">{{ product.id }}</span>
          <b-button
            v-ripple.400="$x.ripple.primary"
            v-clipboard:copy="product.id"
            v-clipboard:success="$x.onCopy"
            v-clipboard:error="$x.onError"
            size="sm"
            variant="flat-primary"
            class="x-link btn-icon rounded-circle x-transparent">
            <feather-icon
              icon="CopyIcon"
            />
          </b-button>
        </b-col>
        <b-col md="3">
          <div class="d-flex flex-row align-items-center">
            <span class="text-muted">发布状态：</span>
            <b-badge
              v-if="product.state === 1"
              variant="light-success">
              已发布
            </b-badge>
            <b-badge
              v-if="product.state === 0"
              variant="light-danger">
              未发布
            </b-badge>
            <b-form-checkbox
              v-if="false"
              class="ml-4"
              checked="true"
              name="check-button"
              switch
              inline>
            </b-form-checkbox>
            <span :class="product.state === 0 ? 'text-primary' : 'text-danger '" class="x-link ml-2" @click="product.state === 0 ? deployProduct('发布成功') : undeployProduct('停用成功')">{{ product.state === 0 ? '发布' : '停用' }}</span>
          </div>
        </b-col>
        <b-col md="3">
          <span class="text-muted">设备数量：</span>
          <b-link
            class="ml-50 font-medium-1 x-link"
            :to="{ name: 'device-center-device-manager-devices', params: { productId: product.id }}"
          >
            {{ count }}
            <feather-icon icon="CornerRightUpIcon" class="text-primary ml-50" size="18"/>
          </b-link>
        </b-col>
      </b-row>
    </b-card>
    <b-card>
      <b-tabs>
        <b-tab
          active
        >
          <template #title>
            <div class="mb-50">
              <feather-icon icon="HomeIcon" size="18"/>
              <span>产品信息</span>
            </div>
          </template>
          <section id="card-actions">
            <product-info :product="product" :configs="configs" @editDone="editDone" @deploy="editDeploy"/>
          </section>
        </b-tab>
        <b-tab lazy>
          <template #title>
            <div class="mb-50">
              <feather-icon icon="BoxIcon" size="18"/>
              <span>物模型</span>
            </div>
          </template>
          <things-model v-model="product" @done="getProductProfile()"/>
        </b-tab>
        <b-tab lazy>
          <template #title>
            <div class="mb-50">
              <feather-icon icon="MapIcon" size="18"/>
              <span>设备分布</span>
            </div>
          </template>
          <product-devices :product-id="product.id" :product-name="product.name"></product-devices>
        </b-tab>
        <b-tab lazy :disabled="!code">
          <template #title>
            <div class="mb-50">
              <feather-icon icon="CodepenIcon" size="18"/>
              <span>协议解析</span>
            </div>
          </template>
          <product-protocol :code="code"></product-protocol>
        </b-tab>
        <b-tab lazy>
          <template #title>
            <div class="mb-50">
              <feather-icon icon="BellIcon" size="18"/>
              <span>告警管理</span>
            </div>
          </template>
          <alarm-manage v-model="product" :objId="product.id" :isProduct="true"/>
        </b-tab>
      </b-tabs>
    </b-card>
  </b-overlay>
</template>
<script>
import {
  BTabs, BTab, BCard, BRow, BCol, BBadge, BAvatar, BButton, VBToggle, BLink,
  VBTooltip, BFormCheckbox, BAlert, BOverlay,
} from 'bootstrap-vue'
import {
  getById, count, getConfig, deploy, unDeploy,
} from '@/api/device-manage/product-list'
import {
  queryById,
} from '@/api/device-access/agreement-list'
import ProductInfo from './ProductInfo.vue'
import ProductDevices from './ProductDevices.vue'
import ProductProtocol from './ProductProtocol.vue'
import ThingsModel from '../things-model/index.vue'
import AlarmManage from '../alarm/AlarmManage.vue'

export default {
  components: {
    ProductProtocol,
    BOverlay,
    BTabs,
    BTab,
    BCard,
    BRow,
    BCol,
    BBadge,
    BAvatar,
    BButton,
    BLink,
    BFormCheckbox,
    ProductInfo,
    ProductDevices,
    ThingsModel,
    AlarmManage,
    BAlert,
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-toggle': VBToggle,
  },
  data() {
    return {
      showLoading: false,
      code: undefined,
      product: {
        id: undefined,
        name: undefined,
        orgId: undefined,
        classifiedName: undefined,
        protocolName: undefined,
        transportProtocol: undefined,
        deviceType: {
          text: undefined,
        },
        describe: undefined,
        photoUrl: undefined,
      },
      count: 0,
      configs: [],
    }
  },
  created() {
    this.refresh()
    this.$x.utils.System.getUnits()
  },
  methods: {
    refresh() {
      // 获取产品信息
      this.getProductProfile()
      count(this.$route.params.id).then(resp => {
        this.count = resp.data.result
      })
    },
    deployProduct(msg, refresh) {
      this.showLoading = true
      deploy(this.product.id).then(() => {
        this.$xtoast.success(msg)
        this.getProductProfile()
      }).catch(() => {
        if (refresh) {
          this.getProductProfile()
        }
      })
    },
    undeployProduct(msg) {
      this.showLoading = true
      unDeploy(this.product.id).then(() => {
        this.$xtoast.success(msg)
        this.getProductProfile()
      })
    },
    getProductProfile() {
      this.showLoading = true
      getById(this.$route.params.id).then(resp => {
        this.product = resp.data.result
        let keysArr = []
        let valuesArr = []
        if (this.product.configuration) {
          keysArr = Object.keys(this.product.configuration)
          valuesArr = Object.values(this.product.configuration)
        }
        getConfig(this.$route.params.id).then(res => {
          const configArr = res.data.result
          this.configs = configArr
          configArr.forEach(item => {
            item.properties.forEach(pop => {
              keysArr.forEach((key, index) => {
                if (key === pop.property) {
                  pop.value = valuesArr[index]
                }
              })
            })
          })
        })
        queryById(this.product.messageProtocol).then(pro => {
          if (pro.data.result.type === 'script') {
            this.code = pro.data.result.configuration.script
          }
        })
        this.showLoading = false
      })
    },
    back() {
      this.$router.go(-1)
    },
    editDone() {
      this.getProductProfile()
    },
    editDeploy(done) {
      deploy(this.product.id).then(() => {
        this.$xtoast.success('应用成功')
        done()
      })
    },
  },
}
</script>
<style>
</style>
